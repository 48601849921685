import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Context as ModalContext } from "../components/Modal/Provider"
import styled, { css } from "../components/styled"
import getColumnSize from "../utils/getColumnSize"
import { Container } from "../components/UI/Grid/Grid"
import Provider from "../components/Provider"
import Header from "../components/Header/Header"
import Link from "../components/UI/Link"
import { MainTitle, Description, Text } from "../components/UI/Typography"
import Footer from "../components/Footer/Footer"
import { Button } from "../components/UI"
import Img, { FluidObject } from "gatsby-image"

const TitleContent = styled(MainTitle)`
  position: relative;
  margin-bottom: 20px;
`

const TextContent = styled(Description)`
  margin-bottom: 60px;
  max-width: 250px;

  ${({ theme: { media } }) => media.above("large")} {
    max-width: 280px;
  }
`

const Wrapper = styled.div`
  min-height: calc(100vh - 90px);
`

const Top = styled.div`
  padding: 32px ${getColumnSize(0)} 0;

  ${({ theme: { media } }) => media.above("large")} {
    padding: 100px ${getColumnSize(1)} 0;
  }
`

const ManifestWrapper = styled.div`
  border-bottom: 1px solid
    ${({ theme: { colors } }) => `${colors.grayscale.mediumLight}30`};
  background-color: ${({ theme: { colors } }) => colors.grayscale.white};
`

const Bottom = styled.div`
  padding: 40px 0 56px;
  display: flex;
  flex-direction: column;

  ${({ theme: { media } }) => media.above("large")} {
    padding: 80px 0 56px;
    flex-direction: row;
  }
`

const BottomBg = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grayscale.light};
`

const ManifestoText = styled(Text)`
  position: relative;
  width: ${getColumnSize(12)};
  max-width: 100%;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      background-color: ${({ theme: { colors } }) => colors.blue.highlight};
      line-height: 22px;
      padding-bottom: 7px;
      padding-top: 5px;
      padding-right: 5px;
    }
  }

  ${({ theme: { media } }) => media.above("large")} {
    max-width: 437px;
  }
`

const ButtonComponent = styled(Button)`
  position: relative;
  margin-right: 24px;

  ${({ ghost }) =>
    ghost &&
    css`
      color: ${({ theme: { colors } }) => colors.grayscale.dark};
      border-color: ${({ theme: { colors } }) => colors.grayscale.mediumLight};

      &:hover {
        border-color: ${({ theme: { colors } }) => colors.grayscale.dark};
        background-color: transparent;
        color: ${({ theme: { colors } }) => colors.grayscale.dark};
      }
    `}
`

const ButtonWrapper = styled.div`
  margin-top: 32px;
`

const ContentWrapper = styled.div`
  margin-left: ${getColumnSize(0)};
  width: ${getColumnSize(12)};
  padding-bottom: 40px;

  ${({ theme: { media } }) => media.above("large")} {
    margin-left: ${getColumnSize(1)};
    width: ${getColumnSize(5)};
    padding-bottom: 0px;
  }
`

const ImageWrapper = styled.div`
  width: ${getColumnSize(12)};
  max-width: 520px;
  align-self: center;

  .gatsby-image-wrapper {
    transform: scaleX(-1);
  }
  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(5)};
    align-self: unset;
  }
`

interface TabItemProp {
  active?: boolean
  [key: string]: any
}

const TabItem = styled(Link)<TabItemProp>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  margin-bottom: 0px;
  margin-left: 2em;
  position: relative;

  padding-top: 0.6em;
  padding-bottom: 0.6em;

  border-bottom: 1px solid
    ${({ theme: { colors }, active }) =>
      active ? colors.blue.default : "transparent"};
  color: ${({ theme: { colors }, active }) =>
    active ? colors.blue.default : colors.grayscale.dark};

  &:first-child {
    margin-left: 0;
  }

  img {
    margin-top: -0.6em;
    margin-left: 0.4em;
    display: inline-block;
  }
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  max-width: 100vw;
  margin-left: -1.25em;
  margin-right: -1.25em;

  padding-left: 1.25em;
  padding-right: 1.25em;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${({ theme: { media } }) => css`
    ${media.above("small")} {
      overflow: visible;
      margin: 0;
      padding: 0;
    }
  `}
`

const NavigationColumn = styled.div`
  flex-wrap: nowrap;
  display: flex;
  white-space: nowrap;
`

interface ManifestoContent {
  markdownRemark: {
    html: string
    frontmatter: {
      title: string
      copy: string
      image: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }
  }
}

const Actions = () => {
  const { setModalOpen } = React.useContext(ModalContext)

  return (
    <>
      <ButtonComponent onClick={() => setModalOpen(true)}>
        I'm In
      </ButtonComponent>
      <ButtonComponent ghost to="/suggest-feature">
        I have an idea!
      </ButtonComponent>
    </>
  )
}

const Manifesto: React.FC = () => {
  const data: ManifestoContent = useStaticQuery(query)
  const basePath = "manifesto"

  return (
    <Provider>
      <Header bgColor="#ffffff" />
      <Wrapper>
        <ManifestWrapper>
          <Container>
            <Top>
              <TitleContent>
                {" "}
                {data.markdownRemark.frontmatter.title}
              </TitleContent>
              <TextContent> {data.markdownRemark.frontmatter.copy}</TextContent>
              <Navigation>
                <NavigationColumn>
                  <TabItem to="/manifesto" active={basePath === "manifesto"}>
                    Join Movement
                  </TabItem>
                  <TabItem to="/nominate-values">Nominate Values</TabItem>
                </NavigationColumn>
              </Navigation>
            </Top>
          </Container>
        </ManifestWrapper>
        <BottomBg>
          <Container>
            <Bottom>
              <ContentWrapper>
                <ManifestoText
                  as="div"
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />

                <ButtonWrapper>
                  <Actions />
                </ButtonWrapper>
              </ContentWrapper>
              <ImageWrapper>
                <Img
                  fluid={
                    data.markdownRemark.frontmatter.image.childImageSharp.fluid
                  }
                  alt="Arm raising a megaphone"
                />
              </ImageWrapper>
            </Bottom>
          </Container>
        </BottomBg>
      </Wrapper>
      <Footer />
    </Provider>
  )
}

export const query = graphql`
  query ManifestoContent {
    markdownRemark(frontmatter: { id: { eq: "manifesto" } }) {
      html
      frontmatter {
        title
        copy
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default Manifesto
